import React from 'react';
import styledComponent, { css } from 'styled-components';

export interface IPhoneInput extends React.InputHTMLAttributes<HTMLInputElement> {
    variant?: 'primary';
    error?: any;
    label? : string;
    tooltip?: string | null;
    showRequired?: boolean;
    onCodeChange?: (code: string) => void;
  }

export const PhoneRelative = styledComponent.div`
    position: relative;
`;

export const PhoneSelectContainer = styledComponent.div`
    display: flex;
    align-items: center;
    gap:8px;
`;

export const Flag = styledComponent.span`
    font-size:23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const SvgContainer = styledComponent.div<{active:boolean}>`
    padding-right:17px;
    height:24px;
    border-right:1px solid #fff;
    display:flex;
    align-items:center;
    svg{
        transition:0.3s all;
        transform: rotate(180deg);
    }
    ${(props) =>
        props.active &&
        css`
            svg{
                transform: rotate(0deg);
            }
        `
    }
`;

export const PhoneSelect = styledComponent.div`
    cursor:pointer;
    position:absolute;
    top:0;
    left:0;
    width:140px;
    height:100%;
    z-index:2;
    color: #fff;
    line-height:16px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:7px;
    padding-left:27px;
`

export const PhoneInputContainer = styledComponent.div`
    display: flex;
    flex-direction: column;
    /* background-color: #000; */
    margin-bottom: 30px;
    position:relative;
    .input-error {
        margin-top: 10px;
        color: #FF4747;
        font-size: 9px;
        padding-left: 15px;
    }
    label {
        padding-left: 10px;
        margin-bottom: 13px;
        font-size: 14px;
        color: #fff;
        font-family: "Main Bold"
        span {
            font-size: 10px;
        }
    }
    .add-link {
        color: #fff;
        font-size: 12px;
        cursor: pointer;
    }
`;

export const StyledInput = styledComponent.input<IPhoneInput>`
    background: #000;
    border: 1px solid #272727;
    height: 68px;
    color: #fff;
    border-radius: 6px;
    padding: 0px 27px  0 154px;
    outline: none;

    &::placeholder {
        color: #626262;
    }
    ${(props) =>
        props.variant === 'primary' &&
        css`
            
        `
    }
`;


export const Modal = styledComponent.div`
    position:absolute;
    top: 76px;
    left:0;
    width:100%;
    border-radius:6px;
    background:#0C0B0B;
    border:1px solid #272727;
    z-index:9;
    `
    
export const ModalHead = styledComponent.div`
    position:relative;
    padding:0 24px;
    height:48px;
`;

export const ModalInputIcon = styledComponent.div`
    position:absolute;
    top:0px;
    left:24px;
    height:100%;
    display:flex;
    align-items:center;
`;

export const ModalInput = styledComponent.input`
    border:none;
    color:#fff;
    border-bottom:1px solid #272727;
    outline:none;
    width:100%;
    height:100%;
    background:transparent;
    padding-left:28px;
`;

export const ModalBody = styledComponent.div`
    padding: 14px 24px 8px 24px;
`;

export const ModalScroll = styledComponent.div`
    padding-right:7px;
    height: 239px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
        border-radius:2px;
    }

    &::-webkit-scrollbar-track {
        background: #272727;
        border-radius:4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const ModalItemLeft = styledComponent.div`
    display:flex;
    align-items:center;
    gap:8px;
    font-size:14px;
`;

export const ModalItem = styledComponent.div`
    height:45px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#fff;
    font-size:16px;
`;