import { FC, useState, useEffect, useRef } from "react";
import {
    Flag,
    IPhoneInput,
    Modal,
    ModalBody,
    ModalHead,
    ModalInput,
    ModalInputIcon,
    ModalItem,
    ModalItemLeft,
    ModalScroll,
    PhoneInputContainer,
    PhoneRelative,
    PhoneSelect,
    PhoneSelectContainer,
    StyledInput,
    SvgContainer,
} from "./PhoneInput.styles";
import TooltipComponent from "../Tooltip/Tooltip.component";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import CODES_DATA from "./dial_codes.json";

export const PhoneInput: FC<IPhoneInput> = ({ variant, label, id, error, tooltip, showRequired, onCodeChange, ...rest }) => {
    const defaultCountry = CODES_DATA.find((code) => code.code === "US");
    const [code, setCode] = useState(defaultCountry);
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCodes, setFilteredCodes] = useState(CODES_DATA);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [open]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const filtered = CODES_DATA.filter((phone_code) =>
                phone_code.name.toLowerCase().startsWith(searchQuery.toLowerCase())
            );
            setFilteredCodes(filtered);
        }, 150); 

        return () => clearTimeout(timeout);
    }, [searchQuery]);

    useEffect(()=>{
        onCodeChange && onCodeChange(code?.dial_code || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleSelect = (code: any) => {
        setCode(code);
        setOpen(false);
        if(onCodeChange) onCodeChange(code.dial_code);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <PhoneInputContainer ref={modalRef}>
            {label && (
                <label htmlFor={id}>
                    {label}
                    {showRequired && <span className="required-field"> *</span>}
                    {tooltip && <TooltipComponent title={tooltip} />}
                </label>
            )}
            <PhoneRelative>
                <StyledInput error={error} variant={variant} id={id} {...rest}/>
                <PhoneSelect onClick={handleToggle}>
                    <PhoneSelectContainer>
                        <Flag>{code?.emoji}</Flag>
                        {code?.dial_code}
                    </PhoneSelectContainer>
                    <SvgContainer active={open}>
                        <ArrowIcon />
                    </SvgContainer>
                </PhoneSelect>
                {open && (
                    <Modal >
                        <ModalHead>
                            <ModalInputIcon>
                                <SearchIcon />
                            </ModalInputIcon>
                            <ModalInput
                                placeholder="Search by country name..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </ModalHead>
                        <ModalBody>
                            <ModalScroll>
                                {filteredCodes.length > 0 ? (
                                    filteredCodes.map((phone_code) => (
                                        <ModalItem
                                            key={`filtered-${phone_code.dial_code + phone_code.name}`}
                                            onClick={() => handleSelect(phone_code)}
                                        >
                                            <ModalItemLeft>
                                                <Flag>{phone_code.emoji}</Flag>
                                                {phone_code.name}
                                            </ModalItemLeft>
                                            {phone_code.dial_code}
                                        </ModalItem>
                                    ))
                                ) : null}
                            </ModalScroll>
                        </ModalBody>
                    </Modal>
                )}
            </PhoneRelative>
            {error && <div className="input-error">{error}</div>}
        </PhoneInputContainer>
    );
};
