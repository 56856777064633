import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageCard } from '../../../Components/ImageCard/ImageCard';
import { useContext, useEffect, useState } from 'react';
import { IEvent } from '../../../utils/interfaces/events.interface';
import { Link } from 'react-router-dom';
// import { LogoLoader } from '../../../Components/LogoLoader/LogoLoader.component';
import './Events.scss';
import { LangsContext } from '../../../context/LangsContext';
import { useDebounce } from 'use-debounce';

const LoaderDiv = () => (
    <div className="events__loading">
        {/* <LogoLoader /> */}
    </div>
)
export const PastEvents = () => {
    const pageLimit = 9;
    const [ events, setEvents ] = useState<IEvent[]>([]);
    const [ page, setPage ] = useState(1);
    const [ hasMore, setHasMore ] = useState(true);
    const [ search, setSearch ] = useState<string | null>(null);
    const [value] = useDebounce(search, 300);

    const content = useContext(LangsContext);
    
    useEffect(()=>{
        getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    const getEvents = async (action?: 'next' | null) => {
        let additionalQuery = value ? `&search=${value}` : '';
        
        await axios.get(`/events/public?status=archived&limit=${pageLimit}&page=${page}${additionalQuery}`)
            .then((res: AxiosResponse)=>{
                const { data } = res;

                data.length < pageLimit && setHasMore(false)
                action === 'next' ? setEvents([...events, ...data]) : setEvents(data);

                setPage(page + 1)
            })
    }

    return(
        <div className="events">
            <h1 className="events__header">
                {content?.PAST || 'PAST'}
            </h1>
            <div className='events__nav'>
                <input type="text" placeholder="Search" className='events__searchbox' onChange={(e) => {
                    setPage(1);    
                    setSearch(e.target.value)
                }} />
            </div>
            <InfiniteScroll 
                className='events__content'
                next={() => getEvents('next')} 
                hasMore={hasMore} 
                loader={<LoaderDiv/>} 
                dataLength={events.length}
            >
                {
                    events.map((event:IEvent) => {
                        return <Link to={`/events/${event.id}`} key={event.id}>
                            <ImageCard
                                url={`${process.env.REACT_APP_HOST}/public/events/${event.cover_url}`}
                                alt={event?.name ?? ''}
                            />
                        </Link>
                    })
                }
            </InfiniteScroll>
        </div>
    )
}