import { Link, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { EEventStatus, IEvent } from "../../../utils/interfaces/events.interface";
import { ImageCard } from "../../../Components/ImageCard/ImageCard";
import { convertDate } from "../../../utils/helpers/convertDate";
import './EventDetail.scss';
import {  LangsContext } from "../../../context/LangsContext";

const EventDetailPage = () => {
    const { id } = useParams();
    const [ event, setEvent ] = useState<IEvent | null>(null);
    const [ lastEvents, setLastEvent ] = useState<IEvent[] | null>(null);
    
    const content = useContext(LangsContext);
    
    useEffect(()=>{
        setEvent(null);
        getEvent();
        getLastEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);

    const getEvent = () => {
        axios.get(`/events/${id}`).then((res:AxiosResponse) => {
            setEvent(res.data);
        })
    }

    const getLastEvents = () => {
        axios.get(`/events/public?status=published&limit=3&page=1&skip=${id}`).then((res:AxiosResponse) => {
            setLastEvent(res.data);
        })
    }
    
    return (
        <div className="event-detail">
            {!event ? '' : <>
                <div className="event-detail__head">
                    <div className="event-detail__info">
                        <div>
                            <h1 className="event-detail__title">
                                {/* {
                                    event.name && event.name.split('@').map((word:string,index: number) =>
                                        <div key={index}> {word}</div>
                                    )
                                } */}
                                
                                {event.name && <div style={{marginBottom: '15px'}}>{event.name}</div>}

                                {event?.basement_name && <div style={{marginBottom: '15px', marginTop: '40px'}}>{event.basement_name}</div>}

                                {
                                    event?.basement_stage && event?.basement_stage.split(',').length > 0 && <div className="event-detail__headline">Basement</div>
                                }
                                {
                                    event?.basement_stage && event?.basement_stage.split(',').map((word:string,index: number) =>
                                        <div key={index}>{word}</div>
                                    )
                                }

                                {event?.studio_name && <div style={{marginBottom: '15px', marginTop: '40px'}}>{event.studio_name}</div>}

                                <p className="event-detail__p">
                                    {
                                        event?.studio_stage && event?.studio_stage.split(',').length > 0 && <div className="event-detail__headline">Studio</div>
                                    }
                                    {
                                        event?.studio_stage && event?.studio_stage.split(',').map((word:string,index: number) =>
                                            <div key={index}>{word}</div>
                                        )
                                    }
                                </p>
                            </h1>
                        </div>
                        {/* log */}
                        {event && event.status === EEventStatus.PUBLISHED && (
                            <a
                                className="event-detail__button"
                                href={event?.ticket_link}
                                rel="noreferrer" 
                                target="_blank"
                            >
                                {content?.BUY_TICKET || 'BUY TICKET'}
                            </a>
                        )}
                        <div className="event-detail__date">{convertDate(event?.start_date, event?.is_time_required)}</div>
                        
                    </div>
                    <div className="event-detail__image">
                        <ImageCard
                            url={`${process.env.REACT_APP_HOST}/public/events/${event?.cover_url}`}
                            alt={event?.name || ''} />
                    </div>
                </div>
                <div className="event-detail__content">
                    {/* <div className="event-detail__subtitle">BASEMENT</div>
                    <div className="event-detail__paragraph">{event?.basement_stage}</div>
                    <div className="event-detail__subtitle">STUDIO</div>
                    <div className="event-detail__paragraph">{event?.studio_stage}</div> */}
                    <pre className="event-detail__paragraph" dangerouslySetInnerHTML={{__html: event?.description.replaceAll('\r\n','</br>')}}/>
                </div>

                {
                    lastEvents?.length !== 0 && (
                        <div className="event-detail__others">
                            <div className="event-detail__others-title">
                                {content?.OTHER_NIGHTS || 'OTHER NIGHTS'}
                            </div>
                            <div className="event-detail__others-content">
                                
                                {lastEvents?.map((event:IEvent) => 
                                    <Link to={`/events/${event.id}`} key={event.id}>
                                        <ImageCard
                                            url={`${process.env.REACT_APP_HOST}/public/events/${event.cover_url}`}
                                            alt={event?.name || ''}
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    )
                }
            </>}
        </div>
    )
}

export default EventDetailPage;