import { useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { ImageCard } from "../../../Components/ImageCard/ImageCard";
import { IResident } from "../../../utils/interfaces/residents.interface";
import './PodcastsDetail.scss';
import { LangsContext } from "../../../context/LangsContext";

const PodcastsDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ resident, setResident ] = useState<IResident | null>(null);
    const [ lastResident, setLastResident ] = useState<IResident[] | null>(null);

    const content = useContext(LangsContext);
    
    useEffect(()=>{
        setResident(null);
        getLastResident();
        getResident();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);
    
    const getResident = () => {
        axios.get(`/residents/public/${id}`).then((res:AxiosResponse) => {
            setResident(res.data);
        })
    }

    const getLastResident = () => {
        axios.get(`/residents/public/?limit=3&page=1&skip=${id}`).then((res:AxiosResponse) => {
            setLastResident(res.data);
        })
    }
    
    return <>
        {
            !resident ? '' : 
            <>
                <div className="podcasts-detail">
                    <div className="podcasts-detail__cover">
                        <div className="podcasts-detail__image">
                            <ImageCard 
                                url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                alt={resident.name}                        
                            />
                        </div>
                        <div className="podcasts-detail__tags">
                            {
                                resident.soundcloud_link && 
                                    <a 
                                        href={resident.soundcloud_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--soundcloud"
                                    >&nbsp;</a>
                            }
                            {
                                resident.instagram_link && 
                                    <a 
                                        href={resident.instagram_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--instagram"
                                    >&nbsp;
                                    </a>
                            }
                            {
                                resident.ra_link && 
                                    <a 
                                        href={resident.ra_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--ra"
                                    >&nbsp;
                                    </a>
                            }
                        </div>
                    </div>
                    <div className="podcasts-detail__info">
                        <p>
                            <h1 className="podcasts-detail__name podcasts-detail__title">{resident.name}</h1>
                            <p className="podcasts-detail__bio"  dangerouslySetInnerHTML={{__html: resident.bio.replaceAll('\r\n','</br>')}}/>
                        </p>
                        <iframe 
                            title="SoundCloud Player"
                            width="100%" 
                            height="20" 
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1847375490&color=%23000000&color=ff0000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&inverse=true">
                        </iframe>
                    </div>
                </div>
                <div className="podcasts-detail__list">
                    <h3 className="podcasts-detail__list-title">Album gallery</h3>
                    <div className="podcasts-detail__list-body">
                        <div className="podcasts-detail__artist">
                            <div className="podcasts-detail__artist-cover">
                                <ImageCard 
                                    url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                    alt={resident.name}                        
                                />
                            </div>
                            <div className="podcasts-detail__artist-info">
                                <div>
                                    <div className="podcasts-detail__artist-title">Brown Lights</div>
                                    <p className="podcasts-detail__artist-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </p>
                                </div>
                                <iframe 
                                    title="SoundCloud Player"
                                    width="100%" 
                                    height="20" 
                                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1847375490&color=%23000000&color=ff0000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&inverse=true">
                                </iframe>
                            </div>
                        </div>
                        <div className="podcasts-detail__artist">
                            <div className="podcasts-detail__artist-cover">
                                <ImageCard 
                                    url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                    alt={resident.name}                        
                                />
                            </div>
                            <div className="podcasts-detail__artist-info">
                                <div>
                                    <div className="podcasts-detail__artist-title">Brown Lights</div>
                                    <p className="podcasts-detail__artist-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </p>
                                </div>
                                <iframe 
                                    title="SoundCloud Player"
                                    width="100%" 
                                    height="20" 
                                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1847375490&color=%23000000&color=ff0000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&inverse=true">
                                </iframe>
                            </div>
                        </div>
                        <div className="podcasts-detail__artist">
                            <div className="podcasts-detail__artist-cover">
                                <ImageCard 
                                    url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                    alt={resident.name}                        
                                />
                            </div>
                            <div className="podcasts-detail__artist-info">
                                <div>
                                    <div className="podcasts-detail__artist-title">Brown Lights</div>
                                    <p className="podcasts-detail__artist-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </p>
                                </div>
                                <iframe 
                                    title="SoundCloud Player"
                                    width="100%" 
                                    height="20" 
                                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1847375490&color=%23000000&color=ff0000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&inverse=true">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="podcasts-detail__others">
                    <div className="podcasts-detail__others-title">
                        {content?.OTHER_RESIDENTS || 'OTHER RESIDENTS'}
                    </div>
                    <div className="podcasts-detail__others-content">
                        {lastResident?.map((resident:IResident) => 
                            <div className="podcasts-detail__others-card" key={resident.id}>
                                <div className="podcasts-detail__others-cover">
                                    <ImageCard
                                        onClick={()=>navigate(`/residents/${resident.id}`)}
                                        url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                        alt={resident.name}
                                    />
                                </div>
                                <div className="podcasts-detail__others-name">{resident.name}</div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        }   
    </>
}

export default PodcastsDetailPage;