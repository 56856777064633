import { IUser } from "./user.interface";

export enum EEventStatus {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
  }
  

export interface IEvent {
  id: string;

  name?: string;
  studio_name? : string;

  description: string;

  start_date: string;

  end_date: string;

  cover_url: string;

  status: EEventStatus;

  ticket_link: string;

  facebook_event_link: string;

  created_by: IUser;

  created_at: string;

  updated_at: string;

  basement_stage: string;

  studio_stage: string;

  is_time_required: boolean;
  basement_name?: string;
}
