export enum PermissionsEnum {
  LATEST_REQUEST_COMPONENT_VIEW = 'LATEST_REQUEST_COMPONENT_VIEW',
  SHOW_STATISTICS = 'SHOW_STATISTICS',
  DASHBOARD_PAGE_VIEW = 'DASHBOARD_PAGE_VIEW',
  MEMBERS_PAGE_VIEW = 'MEMBERS_PAGE_VIEW',
  SHOW_ALL_ROLES = 'SHOW_ALL_ROLES',
  SHOW_LOST_AND_FOUND = 'SHOW_LOST_AND_FOUND',
  UPDATE_LOST_AND_FOUND_STATUS = 'UPDATE_LOST_AND_FOUND_STATUS',
  FRIEND_INVITATION = 'FRIEND_INVITATION',
  ADD_NOTES = 'ADD_NOTES',
  SHOW_ALL_NOTES = 'SHOW_ALL_NOTES',
  SHOW_ONLY_MY_NOTES = 'SHOW_ONLY_MY_NOTES',
  SHOW_SCORE = 'SHOW_SCORE',
  MEMBER_STATUS_CHANGE = 'MEMBER_STATUS_CHANGE',
  SHOW_MEMBER_ROLE = 'SHOW_MEMBER_ROLE',
  VERIFIED_MEMBERS_LIST_VIEW = 'VERIFIED_MEMBERS_LIST_VIEW',
  UNVERIFIED_MEMBERS_LIST_VIEW = 'UNVERIFIED_MEMBERS_LIST_VIEW',
  PENDING_MEMBERS_LIST_VIEW = 'PENDING_MEMBERS_LIST_VIEW',
  BANNED_MEMBERS_LIST_VIEW = 'BANNED_MEMBERS_LIST_VIEW',
  REGISTERED_MEMBERS_LIST_VIEW = 'REGISTERED_MEMBERS_LIST_VIEW',
  INVITE_MEMBERS_PAGE_VIEW = 'INVITE_MEMBERS_PAGE_VIEW',
  INVITE_MEMBER = 'INVITE_MEMBER',
  CANCEL_INVITE = 'CANCEL_INVITE',
  EVENTS_PAGE_VIEW = 'EVENTS_PAGE_VIEW',
  EVENTS_DETAIL_VIEW = 'EVENTS_DETAIL_VIEW',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  RESIDENTS_PAGE_VIEW = 'RESIDENTS_PAGE_VIEW',
  ADD_RESIDENTS = 'ADD_RESIDENTS',
  UPDATE_RESIDENTS = 'UPDATE_RESIDENTS',
  DELETE_RESIDENTS = 'DELETE_RESIDENTS',
  CONTENT_PAGE_VIEW = 'CONTENT_PAGE_VIEW',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  ADDING_ADDITIONAL_ROLES = 'ADDING_ADDITIONAL_ROLES',
  SHOW_ALL_MEMBERS = 'SHOW_ALL_MEMBERS',
  SEE_MEMBER_DETAILS = 'SEE_MEMBER_DETAILS',
  CHANGE_MEMBER_ROLES = 'CHANGE_MEMBER_ROLES',

  CAN_VERIFY_MEMBER = 'CAN_VERIFY_MEMBER',
  CAN_UNVERIFY_MEMBER = 'CAN_UNVERIFY_MEMBER',
  CAN_BAN_MEMBER = 'CAN_BAN_MEMBER',
  CAN_UNBAN_MEMBER = 'CAN_UNBAN_MEMBER',
  CAN_DISABLE_MEMBER = 'CAN_DISABLE_MEMBER',

  ASK_FOR_LETTER = 'ASK_FOR_LETTER',
  CAN_VERIFY_IDENTITY = 'CAN_VERIFY_IDENTITY',
}
