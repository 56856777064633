import { useContext, useEffect, useState } from "react";
import { FormikValues, useFormik } from "formik";
import { useNavigate, NavigateFunction, useSearchParams } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from "axios";
import * as Yup from "yup";

import {Button} from '../../Components/Button/Button.component';
import Input from '../../Components/Input/Input.component';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';
import { useSignIn, useSignOut } from "react-auth-kit";
import TermsAndCondition from "../../Components/TermsAndCondition/TermsAndCondition";
import { LangsContext } from "../../context/LangsContext";
import { PhoneInput } from "../../Components/PhoneInput/PhoneInput.component";
const registrationValidationSchema = Yup.object({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    password: Yup.string().required("Password is required"),
    terms: Yup.boolean().oneOf([true],"Terms and Conditions are required")
});

const RegistrationMember: React.FC = () => {
    const [ code, setCode ] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate() as NavigateFunction;
    const signIn = useSignIn();
    const [termsNotChecked, setTermsNotChecked] = useState<boolean>(true);
    const [termsPopupOpen, setTermsPopupOpen] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const content = useContext(LangsContext);
    console.log(code);
    const onSubmit = async (values: any) => {
        let url = 'users/register'
        if(searchParams.get('hash')) {
            url = 'users/register/invited?hash=' + searchParams.get('hash');
        }
        
        if(!values.terms) {
            setTermsNotChecked(false);
            return;
        }
        setIsLoading(true);

        values.phone = `${code} ${values.phone}`
        console.log(values);
        try {
            await axios.post(url,values)
                .then((res: AxiosResponse) => {
                    const { data } = res;

                    if(data.token)  {
                        signIn({
                            token: data.token,
                            expiresIn: 999999,
                            tokenType: "Bearer",
                            authState: data.user,
                        });

                        navigate('/complete-registration')
                    }
                    else {
                        navigate('/activate-account?view=after-registration')
                    }
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
                
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            email: !searchParams.get('hash') ? '' : searchParams.get('email'),
            password: "",
            full_name: "",
            phone: "",
            terms: false,
        },
        validationSchema: registrationValidationSchema,
        onSubmit,
    });

    const signOut = useSignOut();

    useEffect(() => {
        signOut()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    return (
        <div className="login loginMember">
            <div className="login__container ">
                <div className='login__column login__column--form'>
                    <div className='loginForm'>
                        <LoadingWrapper loading={isLoading}>
                            <div className='loginForm__container'>
                                <div className='loginForm__title'>
                                    {content?.REGISTRATION || 'Registration'}
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <Input
                                        type='text'
                                        placeholder='Full name'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Full name'
                                        name='full_name'
                                        autoComplete="name"
                                        error={formik.touched.full_name && formik.errors.full_name}
                                        value={formik.values.full_name}
                                        onChange={formik.handleChange}
                                        showRequired
                                        tooltip={content?.FULL_NAME_INFO || '-'}
                                    />

                                    <Input 
                                        type='text'
                                        placeholder='Email: example@gmail.com'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Email'
                                        name='email'
                                        disabled={!!searchParams.get('hash')}
                                        autoComplete="username"
                                        error={formik.touched.email && formik.errors.email}
                                        value={!searchParams.get('hash') ? formik.values.email : searchParams.get('email')?.replaceAll(' ', '+')}
                                        onChange={formik.handleChange}
                                        showRequired
                                    />
                                    <PhoneInput 
                                        type='number'
                                        onKeyDown={(evt) => ["e", "E",  "-"].includes(evt.key) && evt.preventDefault()}
                                        placeholder='Phone'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Phone'
                                        name='phone'
                                        autoComplete="tel"
                                        error={formik.touched.phone && formik.errors.phone}
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onCodeChange={(code) => {
                                            setCode(code);
                                        }}
                                        showRequired
                                    />
                                    {/* <Input
                                        type='number'
                                        onKeyDown={(evt) => ["e", "E",  "-"].includes(evt.key) && evt.preventDefault()}
                                        placeholder='Phone: +380123456789'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Phone'
                                        name='phone'
                                        autoComplete="tel"
                                        error={formik.touched.phone && formik.errors.phone}
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        showRequired
                                    /> */}

                                    <Input 
                                        type='password'
                                        placeholder='Password'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Password'
                                        name='password'
                                        showHideToggle
                                        autoComplete="current-password"
                                        error={formik.touched.password && formik.errors.password}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        showRequired
                                    />

                                    {error  && <div className='error-message'>{error}</div>}

                                    <div className='loginForm__actions'>
                                        <div className={`styledCheckbox styledCheckbox--terms ${!termsNotChecked && 'styledCheckbox--error'}`}>
                                            <input type="checkbox" name="terms" onChange={formik.handleChange} />
                                            <div onClick={() => setTermsPopupOpen(true)}>
                                                {content?.TERMS_AND_CONDITIONS || 'Terms and Conditions'}
                                            </div>
                                        </div>

                                        <TermsAndCondition isOpen={termsPopupOpen} onClose={() => setTermsPopupOpen(false)}/>

                                        <Button type={'submit'} variant='red' disabled={!(formik.isValid && formik.dirty)}>
                                            {content?.REGISTER || 'Register'}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationMember;