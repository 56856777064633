import { FormikValues, useFormik } from 'formik';
import './ProfileInvites.scss'
import * as Yup from 'yup';
import Input from '../../Components/Input/Input.component';
import { Button } from '../../Components/Button/Button.component';
import { ISentInvites, IUser } from '../../utils/interfaces/user.interface';
import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import LoadingWrapper from '../Admin/Loading.wrapper';
import { EInvitesStatus } from '../../utils/interfaces/invite-status.enum';
import ProfileInvitesPresentation from './ProfileInvites.presentation';
import SentInvitesPresentation from './newInvites.presentation';
import { EUserStatus } from '../../utils/interfaces/user-status.enum';
import TooltipComponent from '../../Components/Tooltip/Tooltip.component';
import { LangsContext } from '../../context/LangsContext';
import PopupWrapper, { StyledPopupHeader } from '../Admin/Popup.wrapper';

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});

interface IProfileInvitesProps {
    user: IUser;
}

const ProfileInvites: React.FC<IProfileInvitesProps> = ({user}) => {
    const [sentInvites, setSentInvites] = useState<ISentInvites[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [canInvite, setCanInvite] = useState<boolean>(false)

    const content = useContext(LangsContext);
    
    const onSubmit = async (values: any) => {
        setLoading(true)
        
        await axios.post(`invites`, {
            email: values.email
        })
        .then((res: AxiosResponse<{invite: ISentInvites}>) => {
            setSentInvites([...sentInvites, {
                email: values.email,
                status: EInvitesStatus.INVITED,
                id: res.data.invite.id,
                need_letter: false,
                letter: '',
                invite_by: user
            }])
            user.sent_invites.push({
                email: values.email,
                status: EInvitesStatus.INVITED,
                id: res.data.invite.id,
                need_letter: false,
                letter: '',
                invite_by: user
            })
        })
        .catch((err) => {
            console.log(err)
            setError(err.response.data.message)
        })
        setLoading(false)

    }
    
    const formik: FormikValues = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });

    const onDeleteInvite = async (id: string) => {
        formik.resetForm()
        setSentInvites(sentInvites.filter((invite: ISentInvites) => invite.id !== id))
    }

    const [isGuideLineOpen, setIsGuideLine] = useState<boolean>(false)

    useEffect(() => {
        setSentInvites(user.sent_invites.filter((invite: ISentInvites) => invite.status === EInvitesStatus.INVITED))
        
        const canSendInvite = user.sent_invites.filter((invite: ISentInvites) => invite.status === EInvitesStatus.INVITED || invite.status === EInvitesStatus.REGISTERED).length < 3 && user.can_invitation
        
        setCanInvite(canSendInvite)
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="profileInvites">
            <div className='flex flex-jcsb flex-aic profileInvitesHead__title'>
                <h1 className='title'>
                    Invite your friend<span style={{position: 'relative', top: '-10px'}}><TooltipComponent title={content?.INVITE_FRIEND_INFO || '-'} /></span>
                </h1>
                <button className='guideline-icon' onClick={() => setIsGuideLine(true)}>{content?.GUIDELINE_POPUP_TITLE ? content?.GUIDELINE_POPUP_TITLE : 'Guideline'}</button>

                <PopupWrapper isopen={isGuideLineOpen} view='guideline'>
                    <div className="archive">
                        <StyledPopupHeader>
                            <div className="popup-title">{content?.GUIDELINE_POPUP_TITLE ? content?.GUIDELINE_POPUP_TITLE : 'Guideline'}</div>
                            <button className="close" onClick={() => setIsGuideLine(!isGuideLineOpen)}></button>
                        </StyledPopupHeader>

                        <div className="text text--readable text--white" dangerouslySetInnerHTML={{
                            __html: content?.['USER_GUIDELINE'] ? content['USER_GUIDELINE'].replaceAll('\r','</br>').replaceAll('\n', '<br />') : ''
                        }}></div>
                    </div>
                </PopupWrapper>
            </div>
            
            {
                user.can_invitation && sentInvites.length < 3 && canInvite && user.status === EUserStatus.VERIFIED && (
                    <LoadingWrapper loading={loading}>
                        <form onSubmit={formik.handleSubmit}>
                            <Input 
                                type='text'
                                placeholder='Email: example@gmail.com'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Email'
                                name='email'
                                error={formik.touched.email && formik.errors.email}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />

                            {error && (
                                <div className='error-message'>
                                    {error}
                                </div>
                            )}
                            
                            <div className='loginForm__actions flex-jcfe'>
                                <Button type={'submit'} variant='red'>
                                    {content?.INVITE || 'Invite'}
                                </Button>
                            </div>
                        </form>
                    </LoadingWrapper>
                )
            }

            {
                (sentInvites.length >= 3 || !canInvite) && user.can_invitation && (
                    <div className='friendList flex flex-c flex-jcc'>
                        {content?.INVITE_AGAIN_INFO || 'You can invite again after previous invitations have been reviewed!'}
                    </div>
                )
            }

            {
                (!user.can_invitation || user.status !== EUserStatus.VERIFIED) && (
                    <div className='friendList flex flex-c flex-jcc'>
                        {content?.NO_PERMISSION_TO_INVITE || 'You don’t have permission to invite friends'}
                    </div>
                )
            }
            
            <h1 className='title'>
                {content?.FRIENDS || 'Friends'}
            </h1>

            <SentInvitesPresentation onDeleteInvite={onDeleteInvite} sentInvites={sentInvites} />

            <ProfileInvitesPresentation user={user} />

            {
                user.invites.length === 0 && sentInvites.length === 0 && (
                    <div className='friendList flex flex-c flex-jcc'>
                        {content?.NO_INVITES_SENT || 'No invites sent'}
                    </div>
                )
            }
        </div>
    )
}

export default ProfileInvites;