import { FormikValues, useFormik } from "formik"
import Input from "../Input/Input.component"
import './LostAndFoundPopup.scss';
import Textarea from "../Textarea/Textarea.component";
import { Button } from "../Button/Button.component";
import * as Yup from 'yup';
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { InputContainer } from "../Input/Input.styles";
import TooltipComponent from "../Tooltip/Tooltip.component";
import { LangsContext } from "../../context/LangsContext";

const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    phone: Yup.string().required("Phone is required"),
    date: Yup.string(),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    item: Yup.string(),
    description: Yup.string().required("Comment is required"),
})

export const LostAndFoundPopup = () => {
    const [ error, setError ] = useState("");
    const [ sent, setSent ] = useState(true);

    const content = useContext(LangsContext);
    
    useEffect(() => {
        axios.get('lostandfound/me').then((me:AxiosResponse)=>{
            setSent(me.data.success);
        }).catch((e)=>{ console.warn(e)})
    },[])

    const onSubmit = (values:FormikValues) => {
        
        axios.post(`/lostandfound`,values)
            .then((data:AxiosResponse)=>{
                if(data.status === 201) setSent(false);
            })
            .catch((e:AxiosError) => setError(e.message));
        formik.resetForm();
    }
   
    const formik = useFormik({
        initialValues:{
            fullName: "",
            date: "",
            phone: "",
            email: "",
            item: "",
            description: ""
        },
        validationSchema,
        onSubmit
    })

    return !sent ? 
        <div className="lnf">
            <div className="lnf__success">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" fill="#8FFF68" stroke="#8FFF68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <span className="lnf__success-msg">Message has been sent</span>
            </div>
        </div> :
        <form onSubmit={formik.handleSubmit} className="lnf">
            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='fullName'>Full name <span className="required-field"> *</span></label>
                <Input
                    type='text'
                    id="fullName"
                    placeholder='Full name'
                    className='lnf__input loginForm__input--noRounded'
                    name='fullName'
                    error={formik.touched.fullName && formik.errors.fullName}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                />
            </InputContainer>

            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='date'>Date</label>
                <Input
                    type='date'
                    id="date"
                    placeholder='Date'
                    className='lnf__input loginForm__input--noRounded'
                    name='date'
                    error={formik.touched.date && formik.errors.date}
                    value={formik.values.date}
                    onChange={formik.handleChange}  
                />
            </InputContainer>
            
            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='phone'>Phone <span className="required-field"> *</span></label>
                <Input
                    type='text'
                    id="phone"
                    placeholder='Phone'
                    className='lnf__input loginForm__input--noRounded'
                    name='phone'
                    error={formik.touched.phone && formik.errors.phone}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                />
            </InputContainer>

            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='email'>Email <span className="required-field"> *</span></label>
                <Input
                    type='text'
                    id="email"
                    placeholder='Email'
                    className='lnf__input loginForm__input--noRounded'
                    name='email'
                    error={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                />
            </InputContainer>

            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='phone'>Item name</label>
                <Input
                    type='text'
                    placeholder='Item name(opt)'
                    className='lnf__input loginForm__input--noRounded'
                    name='item'
                    error={formik.touched.item && formik.errors.item}
                    value={formik.values.item}
                    onChange={formik.handleChange}
                />
            </InputContainer>

            <InputContainer style={{marginBottom: 0}}>
                <label htmlFor='description'>
                    Description <span className="required-field"> *</span>
                    <TooltipComponent title={content?.LOST_AND_FOUND_DESCRIPTION || 'Write your lost item...'} />
                </label>
                
                <Textarea
                    placeholder='Description'
                    id="description"
                    className='lnf__input loginForm__input--noRounded'
                    name='description'
                    error={formik.touched.description && formik.errors.description}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                />
            </InputContainer>
            
        <div className="lnf__buttons">
            <div className='lnf__alert'>
                {content?.LOST_AND_FOUND_ALERT || `To ensure you receive our messages, please check spam folder on your email`}
            </div>
            <span className="lnf__error">{error}</span>
            <Button type="submit" variant="green-submit">Send</Button>
        </div>
        </form>
}