import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageCard } from '../../../Components/ImageCard/ImageCard';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResident } from '../../../utils/interfaces/residents.interface';
// import { LogoLoader } from '../../../Components/LogoLoader/LogoLoader.component';
import './Podcasts.scss';
import { LangsContext } from '../../../context/LangsContext';

const LoaderDiv = () => (
    <div className="public-podcasts__loading">
        {/* <LogoLoader /> */}
    </div>
)

export const Podcasts = () => {
    const navigate = useNavigate();
    const pageLimit = 9;
    const [ residents, setResidents ] = useState<IResident[]>([]);
    const [ page, setPage ] = useState(1);
    const [ hasMore, setHasMore ] = useState(true);

    const content = useContext(LangsContext);
    
    useEffect(()=>{
        getResidents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getResidents = async (action?: 'next' | null) => {
        await axios.get(`/residents/public/?limit=${pageLimit}&page=${page}`)
            .then((res: AxiosResponse)=>{
                const { data } = res;
                data.length < pageLimit && setHasMore(false)
                action === 'next' ? setResidents([...residents, ...data]) : setResidents(data);

                setPage(page + 1)
            })
    }

    return(
        <div className="public-podcasts">
            <h1 className="public-podcasts__header">
                {content?.OUR_RESIDENTS || 'Podcasts'}
            </h1>
            <InfiniteScroll 
                className='public-podcasts__content'
                next={() => getResidents('next')} 
                hasMore={hasMore} 
                loader={<LoaderDiv/>} 
                dataLength={residents.length}
            >
                {
                    residents.map((resident:IResident) => {
                        return <div className='public-podcasts__card' key={resident.id}>
                            <div className="public-podcasts__card-cover">
                                <ImageCard
                                    onClick={()=>navigate(`/podcasts/${resident.id}`)}
                                    url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                    alt={resident.name}
                                />
                            </div>
                            <p className="public-podcasts__card-title">{resident.name}</p>
                            <p className="public-podcasts__card-subtitle">Artist</p>
                        </div>
                        
                    })
                }
            </InfiniteScroll>
        </div>
    )
}